.nav-main {
	text-align: center;

	&__level0 {
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin: 0;

		> li {
			display: inline-block;

			a {
				display: block;
				padding: 19.5px 20px;
				color: #fff;
				text-decoration: none;
				font: {
					size: 18px;
					family: $header-font-family;
				}

				@include breakpoint(medium) {
				    padding: 19.5px 5px;
				}

				&:hover {
					color: $tertiary-color;
				}
			}

			&.active {
				a {
					text-decoration: underline;
					color: $tertiary-color;
				}
			}
			&.logo{
				text-indent: -9999px;
				min-width: 300px;

				@include breakpoint(medium) {
				    min-width: 190px;
				}
			}

			&:first-child{
				a {
					padding-left: 0px;

					@include breakpoint(medium) {
					    padding-left: 10px;
					}
				}
			}
			&:last-child{
				a {
					padding-right: 0px;

					@include breakpoint(medium) {
					    padding-right: 10px;
					}
				}
			}
		}

	}
}