.nav-footer {
	padding: 12px 0;

	&__level0 {
		> li {
			display: inline-block;
			a {
				padding: 0 10px;
				text-decoration: none;
				color: #fff;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}