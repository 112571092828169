.nav-social {
	float: right;
	
	&__level0 {
		padding: 0;
		margin: 0;
		
		> li {
			display: inline-block;
			position: relative;

			a {
				display: block;
				padding: 10px 5px 5px 0px;
			}
			
			&.has-children {

					&:hover {
						.nav-social__level1{
							display: block !important;
						}
					}

			}

			&:last-child {
				background: #f3c100;
				margin: 0 0 0 15px;

				a {
					color: $primary-color;
					height: 45px;
					padding: 10px 10px 0px 10px;

					&:hover {
						background: $primary-color;
						color: #fff;
					}
				}
			}
		}
	}


	&__level1 {
		display: none;
		position: absolute;
		background: $primary-color;
		z-index: 9;

		> li {
			display: block;
			a {

			}
		}
	}
}