.page-header {
	
	padding: 0;
	margin: 0;
	border: none;
	
	&__logo {
		position: absolute;
		top: 15px;
		z-index: 9;
		left: 50%;
		transform: translateX(-50%);

		@include breakpoint(medium) {
		    top: -15px;
		}
	}

	&__top {
		min-height: 45px;
		background: #fff;

		.wrapper {
			display: flex;
			justify-content: flex-end;
		}
	}	

	&__bottom {
		min-height: 55px;
		background: $primary-color;
		border-bottom: 15px solid $secondary-color;
	}

	@include breakpoint(small) {
	    &__bottom {
	    	display:none;
	    }
	}
}