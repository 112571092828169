.copyright {
	text-align: center;
	padding: 25px 0;
	line-height: 20px;

	a {
		text-decoration: none;
		color: $secondary-color;

		&:hover {
			text-decoration: underline;
		}
	}
}