.pagination {
	display: block;
	width: 100%;
	padding: 50px 0;
	
	ol {
		padding: 0;

		> li {
			display: inline-block;
			border: 1px solid $primary-color;
			background: #fff;
			color: $primary-color;
			

			a {
				color: $primary-color;	
				text-decoration: none;
				display: block;
				padding: 5px 10px;
			}

			&:hover {
				background: $primary-color;
				a {
					color: #fff;
				}
			}

			&.active {
				background: $primary-color;
				color: #fff;
				padding: 5px 10px;
			}

			&.off {
				border: 1px solid #999;
				color: #999;
				padding: 5px 10px;

				&:hover {
					background: #Fff;
				}
			}
		}
	}
}