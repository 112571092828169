.button {

	&__primary {
		background: $tertiary-color;
		color: $global-font-color;
		text-decoration: none;
		padding: 10px 40px;
		border: none;
		font-size: 16px;

		&:hover {
			background: $primary-color;
			color: #fff;
		}
	}

	&__member {
		background: white url(../../resources/img/member.png);
		width: 280px;
		padding: 14px 0 0 20px;
		display: block;
		text-decoration: none;
		color: $global-font-color;
		font-size: $global-font-size - 2px;
	}

	&__accept {
		background: $tertiary-color;
		color: $global-font-color !important;
		text-decoration: none;
		padding: 7px 15px;
		border: none;
		font-size: 16px;

		&:hover {
			background: darken($tertiary-color, 10%);
			color: #fff;
		}
	}

	&__more {
		background: $global-border-color;
		color: $global-font-color !important;
		text-decoration: none;
		padding: 7px 15px;
		border: none;
		font-size: 16px;

		&:hover {
			background: darken($global-border-color, 15%);
			color: #fff;
		}
	}
}