.page-footer {
	background: $primary-color;
	padding: 15px 0;
	background-image: url(../../resources/img/watermerk.png);
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 100px;
	border-bottom: 15px solid $secondary-color;

	h4,
	p {
		color: white;
	}

	&.home {
		background-image: url(../../resources/img/watermerk-groot.png);
		background-repeat: no-repeat;
		background-position: left 75%;
	}

	&__top {
		margin-top: -15px;
		position: relative;

		h2 {
			position: absolute;
			top: 50px;
			left: calc((100vw - 960px) / 2);
			font-size: 70px;
			line-height: 80px ;
			color: #fff;

			@include breakpoint(small) {
			    font-size: 40px;
			    line-height: 40px;
			    top: 30px;
			    left: 50%;
			    transform: translateX(-50%);
			    text-align: center;
			}

			@include breakpoint(medium) {
			    font-size: 50px;
			    line-height: 50px;
			    top: 50px;
			    left: 50%;
			    transform: translateX(-50%);
			}
		}

		img{
			width: 100%;

			@include breakpoint(small) {
			    max-width: 100%;
		        object-fit: cover;
		        height: 200px;
			}

			@include breakpoint(medium) {
			    max-width: 100%;
		        object-fit: cover;
		        height: 250px;
			}
		}
	}
	&__bottom {
		padding: 15px 0;
		h3 {
			color: #fff;
			left: 50%;
			transform: translateX(-50%);
			position: relative;
			display: inline-block;
			font-size: 36px;
			padding: 30px 0 30px 0;

			&:before {
				content: url(../../resources/img/chatbuble.png);
				display: block;
				position: absolute;
			    bottom: 50px;
			    left: -100px;

			    @include breakpoint(medium) {
					left: 50%;
					transform: translateX(-50%);
					bottom: 75px		        
			    }

			    @include breakpoint(small) {
			        display: none;
			    }
			}

			@include breakpoint(small) {
			    line-height: 40px;
			    text-align: center;
			}
		}

		@include breakpoint(medium) {
		    padding: 15px;
		}
		@include breakpoint(small) {
		    padding: 15px;
		}
	}

	.stories {
		@extend .grid;
		margin-top: 0px;
		padding: 0;
		
		> li {
			@extend .grid__33;
			background: rgba($global-border-color, 0.9);
			list-style: none;
			border-radius: $global-radius;
			margin-bottom: 15px;
			
		}

		&__inner {
			padding: 15px;

			p {
				color: $global-font-color;
			}

			a {
				color: $secondary-color;
				text-decoration: none;
				
				&:hover{	
					text-decoration: underline;
				}
			}
		}
	}
}