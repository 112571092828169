@mixin headings($h1: 28px, $decreaseBy: 6px) {
    @for $i from 1 through 6 {
      	h#{$i} {
         	margin: 0 0 25px 0;
         	font-size: ($h1+$decreaseBy) - $i * $decreaseBy;
         	font-family: $header-font-family;
         	color: $global-font-color;

	      	@if $i == 1 {
				border-bottom: 2px solid $tertiary-color !important;
				display: inline-block;
				padding: 0 0 15px 0;
	      	}
      	}
    }
}

@include headings(40px, 7px);