// http://meyerweb.com/eric/tools/css/reset/
// v2.0 | 20110126
// License: none (public domain)

@mixin css-reset {
  html, body , fieldset{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: $global-font-family;
    vertical-align: baseline;
  }

  // HTML5 display-role reset for older browsers
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: square;
    padding: 0 0 0 20px;
    line-height: 28px;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote {
    &:before, &:after {
      content: "";
      content: none;
    }
  }
  q {
    &:before, &:after {
      content: "";
      content: none;
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
}

@include css-reset;