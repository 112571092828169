.grid {
	$gutter: 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 25px 0 0 0;

	&__end {
		display: flex;
		justify-content: flex-end;
	}

	> div,
	  li {
	  	list-style-type: none;
	  	
		img {
			max-width: 100%;
		}
	}
	
	&__25 {
		flex: 0 0 calc(25% - #{$gutter});
		max-width: calc(25% - #{$gutter});
		width: calc(25% - #{$gutter});
	}

	&__33 {
		flex: 0 0 calc(100% / 3 - #{$gutter});
		max-width: calc(100% / 3 - #{$gutter});
		width: calc(100% / 3 - #{$gutter});
	}

	&__50 {
		flex: 0 0 calc(50% - #{$gutter});
		max-width: calc(50% - #{$gutter});
		width: calc(50% - #{$gutter});
	}

	&__66 {
		flex: 0 0 calc(100% / 3 * 2 - #{$gutter});
		max-width: calc(100% / 3 * 2 - #{$gutter});
		width: calc(100% / 3 * 2 - #{$gutter});
	}

	&__75 {
		flex: 0 0 calc(75% - #{$gutter});
		max-width: calc(75% - #{$gutter});
		width: calc(75% - #{$gutter});
	}

	&__100{
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
	}

	@include breakpoint(small) {
	    > div, li {
	    	flex: 0 0 100%;
	    	max-width: 100%;
	    	width: 100%;
	    	
	    	// > div {
	    	// 	padding: 15px;
	    	// }

	    }
	}
}