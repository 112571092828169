.cookie {

    &__buttons {
        display: inline-block;
    }

    &__container {
        display: none;
        position: fixed;
        bottom: 0px;
        width: 100%;
        background: $primary-color;
        text-align: center;
        padding: 15px;
        color: #fff;
        z-index: 9;

        &.show {
            display: block;
        }

        a {
            margin: 0 5px;
            color: $tertiary-color;

            &.decline {
                color: #fff;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        p {
            display: inline-block;
            margin: 0;
            padding: 0;
        }
    }

    @include breakpoint(small){
        &__container {
            p {
                padding: 0px 0 15px 0;
            }
        }
    }
}