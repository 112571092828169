.page-main {
	@extend .wrapper;
	background: white;
	padding: 25px 0px 75px 0;

	img {
		max-width: 100%;
		margin: 0 0 15px 0;
	}

	h1 {
		margin-bottom: 25px;
	}

	@include breakpoint(small) {
	    padding: 10px 15px 25px 15px;

	    h1 {
	    	margin-bottom: 5px;
	    	line-height: 2.5rem;
	    }
	}

	@include breakpoint(medium) {
	    padding: 50px 15px;
	}
}