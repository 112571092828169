.stories {
	@extend .grid;

	&__item {
		@extend .grid__50;
		position: relative;
		margin-bottom: 20px;
		background: $primary-color;

		&:first-child {
			&:after {
				content: '';
				display: block;
				position: absolute;
			    top: -30px;
			    height: 30px;
			    width: 100%;
			    overflow: hidden;
			    background-image: url('../../resources/img/rounding-service-green.png');
			    background-size: 100%;
			    background-repeat: no-repeat;

			    @include breakpoint(small) {
			    	width: 100%;
			        top: -19px;
			    }

			    @include breakpoint(medium) {
			    	max-height: calc((100vw / 2) - 30px );
			        top: -20px;
			    }
			}
		}
	}

	&__text{
		padding: 50px 25px;
		

		h2 {
			color: #fff;
			font-weight: 400;
			font-size: 28px;
			line-height: 2.2rem;
		}

		p {
			color: #fff;
		}

		a {
			position: absolute;
			right: 0;
			bottom: 10px;

			&:hover {
				background-color: $secondary-color;
			}
		}
	}
}