.wrapper {
	max-width: 960px;
	margin: 0 auto;
	display: block;
}

p,
h1,
h2,
h3,
h4 {
	line-height: 1.7rem;
}

p {
	margin: 0 0 15px 0;
	font-family: $global-font-family;
	font-weight: $global-font-weight;
	letter-spacing: 0.4px;
	line-height: 28px;

	strong {
		font-weight: $header-font-weight;
	}
	em {
		font-style: italic;
	}

	a {
		text-decoration: none;
		color: $secondary-color;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-entry {

}

.clear {
	clear: both;
}