.breadcrumbs {
	padding: 12px 20px;
	margin: 0;
	text-align: right;

	li {
		display: inline-block;

		a {
			color: $secondary-color;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&:after {
			content: '\00bb';
			display: inline-block;
			margin: 0 5px 0 5px ;
			color: darken($secondary-color, 20%);
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&--mobile{
		background: $secondary-color;
	}
}