.nav-mobile {
	display: none;
	
	&.active {
		display: block;	
	}
	
	&__level0 {
		margin: 0 -15px;
		padding: 0 15px;

		> li {
			list-style: none;
			border-top: 1px solid #fff;

			> a {
				color: #fff;
				text-decoration: none;
				padding: 8px 20px;
				display: block;
			}

			&.active {
				> a {
					background: $secondary-color;
				}

				&.nav-mobile__has-children {
					> a {
						background: none;
						text-decoration: underline;
					}
				}
			}

			&.logo {
				display: none;
			}


		}
	}

	&__level1 {
		padding: 0;

		> li {

		
			> a {
				color: #fff;
				text-decoration: none;
				padding: 5px 20px;
				display: block;

				&:before {
					content: '\00bb';
					display: inline-block;
					margin-right: 10px;
				}
			}

			&.active{
				a {
					background: $secondary-color;
				}
			}
		}
	}
}