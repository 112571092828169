.nav-top {
	float: right;
	text-align: right;
	margin-right: 40px;

	&__level0 {
		display: block;
		> li {
			display: inline-block;
			position: relative;

			a {
				display: block;
			    padding: 16.5px 5px;
			    font-size: 16px;
			    color: #fff;
			    text-decoration: none;
			    min-width: 75px;
			    text-align: center;
			}

			&.active {
				a {
					background: $secondary-color;

					&:after {
						content: "";
					    width: 0;
					    height: 0;
					    border-left: 10px solid transparent;
					    border-right: 80px solid transparent;
					    border-top: 10px solid #009fe3;
					    display: block;
					    position: absolute;
					    bottom: -10px;
					    left: -10px;
					}
				}

			}
		}
	}
}