.slider {
	margin: 0;
	padding: 0;


	&--home {
		position: relative;
		width: 100%;
		margin: 0;

		img {
			width: 100%;
		}

		#janslider{
			z-index: 8;
			width: 100% ;
			padding-bottom: 42.5%;

			img {
				width: 100%;
			}
		}

		&:after {
			content: url(../../resources/img/rounding-slider.png);
			display: block;
			position: absolute;
			bottom: -12px;
			left: calc(((100vw - 1024px) / 2) + 50px);
			max-width: 100vw;
			overflow: hidden;
			z-index: 9;

			@include breakpoint(medium) {
			    left: 0;
			}
			@include breakpoint(small) {
			    left: 0;
			}
		}
	}

	&--empty {
		height: 60px;

		@include breakpoint(small){
			display: none;
		}
	}

	&--page {
		position: relative;

		img {
			width: 100%;
		}

		&:after {
			content: url(../../resources/img/rounding-slider.png);
			display: block;
			position: absolute;
			bottom: -12px;
			left: calc(((100vw - 1024px) / 2) + 50px);
			max-width: 100vw;
			overflow: hidden;

			@include breakpoint(medium) {
			    left: 0;
			}
			@include breakpoint(small) {
			    left: 0;
			}
		}
	}


}