.form {
	position: relative;
	padding: 20px;
	background: $global-border-color;
	border-bottom: 7px solid $secondary-color;
	
	h3 {
		color: $global-font-color;
	}
	&__row {

	}

	&__label{
		display: none;
	}

	&__field{
		margin-bottom: 15px;
	}
	
	input[type=text],
	input[type=email],
	textarea{
		border: none;
		border-radius: $global-radius;
		width: calc(100% - 20px);
		display: block;
		min-height: 40px;
		font-size: $global-font-size;
		padding: 0 10px; 
	}

	textarea {
		padding-top: 10px;
	}
	
	&__note {
		display: none;
	}

	&--belMailTerug {
		&:after {
			content: '';
			display: block;
			position: absolute;
		    top: -20px;
		    right: 0;
		    width: 100%;
		    height: 20px;
		    background-image: url('../../resources/img/rounding-form.png');
		    background-size: 100%;
		    background-repeat: no-repeat;

		    @include breakpoint(small) {
		        top: -19px;
		    }

		    @include breakpoint(medium) {
		        top: -16px;
		    }
		}
	}

	&--contact{
		&:after {
		    content: '';
			display: block;
			position: absolute;
		    top: -43px;
		    right: 0;
		    width: 100%;
		    height: 43px;
			background-image: url('../../resources/img/rounding-form-50.png');
		    background-size: 100%;
		    background-repeat: no-repeat;

		    @include breakpoint(small) {
		        top: -25px;
		    }

		    @include breakpoint(medium) {
		        top: -30px;
		    }
		}
	}

	.button {
		@extend .button__primary;
		margin: 15px 0 0 0;
	}
}