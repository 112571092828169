// COLORS
$primary-color: rgb(0,98,67); // DONDERGROEN
$secondary-color: rgb(100,188,163); // LICHTGROEN
$tertiary-color: rgb(243,193,0); // GEEL
$global-border-color: rgb(238,238,238);

// FONT
$global-font-family: 'Archivo Narrow', sans-serif;
$header-font-family: $global-font-family;
$global-font-color: rgb(51,51,51);
$global-font-size: 16px;
$global-font-weight: 400;
$header-font-weight: 700;

$global-radius: 3px;

// BREAKPOINTS
@mixin breakpoint($point) {
   @if $point == xlarge {
     @media (min-width: 90.063em) { @content ; }
  }
   @else if $point == large {
     @media (min-width: 64.063em) and (max-width: 90em) { @content ; }
  }
    @else if $point == medium {
     @media (min-width: 40.063em) and (max-width: 64em) { @content ; }
  }
  @else if $point == small {
     @media (max-width: 40em)  { @content ; }

  }
}