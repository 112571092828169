.listview {
	margin: 50px 0 0 0;
	padding: 0;

	&__item {
		@extend .grid;
		margin-bottom: 0px;
		padding: 0 0 20px 0;
		position: relative;

		&:first-child {
			.grid__50 {
				&:first-child {
					&:after {
						content: '';
						display: block;
						position: absolute;
					    top: -30px;
					    height: 30px;
					    width: 100%;
					    overflow: hidden;
					    background-image: url('../../resources/img/rounding-service.png');
					    background-size: 100%;
					    background-repeat: no-repeat;

					    @include breakpoint(small) {
					    	width: 100%;
					        top: -19px;
					    }

					    @include breakpoint(medium) {
					    	max-height: calc((100vw / 2) - 30px );
					        top: -20px;
					    }
					}
				}
			}
		}
	}

	&__date {
		font-style: italic;
		color: lighten($global-font-color, 20%);
	}

	&__text{
		padding: 50px 25px;

		h2 {
			color: #fff;
			font-weight: 400;
			line-height: 2.5rem;
		}
	}

	&__odd {
		flex-direction: row-reverse;

		a {
			left: 0px;
			right: auto !important;
		}
	}

	&__50 {
		@extend .grid__50;
		position: relative;

		img {
			width: 100%;
			vertical-align: top;
		}
		a {
			position: absolute;
			bottom: 20px;
		    right: 0px;
		}
	}

	&__img {
		position: absolute;
	}

	&__yellow {
		background: $tertiary-color;
	}
	&__green {
		background: $primary-color;
	}
}