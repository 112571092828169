.mobile-header {
	background: $primary-color;
	border-bottom: 1px solid #fff;
	display: none;
	
	&__hamburger {
		display: inline-block;
		padding: 10px 15px;

		&:before {
			@include sprite( $hamburger );
			content:'';
			display: inline-block;
			position: relative;
			top: 4px;
		}
		
	}

	&__logo {
		display: inline-block;
		position: relative;
    	top: -5px;
		color: #fff;
		font-weight: bold;

		a {
			color: #fff;
			text-decoration: none;
		}
	}

	&.active {
		.mobile-header__hamburger {
			&:before {
				@include sprite( $close );
				content:'';
				display: inline-block;
				position: relative;
				top: 4px;
			}
		}
	}

	@include breakpoint(small) {
	    display: block;
	}
}
